const OurStory = () => {
  return (
    <div className="bg-ourStoryBg bg-cover px-p83" id="story">
      <div className="grid grid-cols-1 800:grid-cols-2 items-center w-full pt-10 500:pt-20 gap-10 pb-10 500:pb-16 800:pb-36 justify-between">
        <span className="text-bodyText text-md 500:text-lg">
          <h2 className="text-3xl 500:text-4xl font-merry-bold tracking-tighter pb-3 text-black">
            Our <span className="font-bold text-txtclr"> Story </span>
          </h2>
          <p className="font-poppins-regular">
            At Nexasoft, our story is one of innovation, determination, and the
            relentless pursuit of excellence. Our team is not just a group of
            professionals; we're a lean, mean machine composed of former startup
            founders and tech evangelists.
          </p>{" "}
          <br />
          <p className="font-poppins-regular">
            We've walked the entrepreneurial path, understanding the challenges
            and opportunities it presents. In the ever-evolving tech landscape,
            we firmly believe that innovation and harnessing the best talent are
            not optional but imperative. We've experienced firsthand the
            difficulties of nurturing and growing a startup, from taking it from
            ground zero to scaling it to new heights.
          </p>
        </span>
        <div>
          <img
            src="Images/ourStoryImg.svg"
            alt="ourStoryImg"
            className="float-end width__resp"
          />
        </div>
      </div>
    </div>
  );
};

export default OurStory;
