import { useEffect, useState } from "react";
import FeedbackCard from "../../components/FeedbackCard";
import { feedbackProps } from "../../utils/interfaces";
import { clientData } from "../../utils/constants";
import { Carousel } from "@mantine/carousel";

const Feedback = () => {
  const [data, setData] = useState<Array<feedbackProps>>([]);
  // Fetch Data from API

  // const getData = async () => {
  //   const response = await getFeedbackData();
  //   setData(response);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  // Static Data

  useEffect(() => {
    setData(clientData);
  }, []);

  return (
    <div
      className="bg-feedbackBg bg-cover px-p83 pb-12 500:pb-24 select-none "
      id="testimonials"
    >
      <h2 className="font-merry-bold tracking-tighter text-3xl 500:text-4xl text-center pt-12 500:pt-24 pb-10">
        Feedback From <br />
        <span className="font-bold text-txtclr"> Our Client </span>
      </h2>
      <Carousel
        slidesToScroll={1}
        slideSize="15%"
        slideGap="md"
        controlSize={25}
        loop
      >
        {data.length > 0 ? (
          data.map(({ _id, Name, company, description, image, position }) => (
            <Carousel.Slide key={_id}>
              <FeedbackCard
                image={image}
                username={Name}
                company={company}
                position={position}
                content={description}
              />
            </Carousel.Slide>
          ))
        ) : (
          <p className="font-semibold text-center w-full py-2 text-xl">
            No Data Available
          </p>
        )}
      </Carousel>
    </div>
  );
};

export default Feedback;
