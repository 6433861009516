import { useEffect, useState } from "react";
import { blogDataTypes } from "../utils/interfaces";
import { getBlogData } from "../utils/apiHandler";
import { useParams } from "react-router";
import { blogsData } from "../utils/constants";

const BlogDetailPage = () => {
  const { id } = useParams();

  // Dynamic Data From API

  // const [data, setData] = useState<blogDataTypes | null>(null);
  // const [dataLoaded, setDataLoaded] = useState(false);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const response = await getBlogData();

  //       const [filterData] = response.filter(
  //         (blog: blogDataTypes) => blog._id === id
  //       );

  //       setData(filterData);
  //       setDataLoaded(true);
  //     } catch (error) {
  //       console.error("Error fetching blog data:", error);
  //     }
  //   };

  //   getData();
  // }, [id]);

  // static data

  const [data, setData] = useState<blogDataTypes | null>(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const filteredData = blogsData.filter((blog) => {
      const ID = +id!;
      return blog._id === ID;
    })[0];

    setData({
      _id: filteredData._id.toString(),
      image: filteredData.image,
      title: filteredData.title,
      description: filteredData.description,
      tags: filteredData.tags,
    });
    setDataLoaded(true);
  }, []);

  return (
    <div>
      {dataLoaded ? (
        <div className="px-p83 pb-10 pt-5 bg-[#e9f5ff] min-h-screen">
          <p className="text-xl 400:text-2xl font-semibold ">{data?.title}</p>
          <img
            src={data?.image}
            alt="Blog Post"
            className="rounded-xl object-cover my-5 w-full 750:max-w-[600px]"
          />
          <div className="py-2">
            <p
              className="py-2 text-bodyText"
              dangerouslySetInnerHTML={{ __html: data?.description || "" }}
            />

            <span className="flex gap-x-p10 flex-wrap gap-y-2">
              {data?.tags.map((tag, i) => (
                <p key={i} className="px-p10 py-1 bg-[#ffa43833] rounded-lg">
                  {tag}
                </p>
              ))}
            </span>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default BlogDetailPage;
