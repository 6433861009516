import { differentFromOther } from "../../utils/constants";
import { DifferentSubComponentProps } from "../../utils/interfaces";

const HowWeDifferent = () => {
  return (
    <div
      className="py-10 500:py-20 px-p83 bg-white grid gap-10 grid-cols-1 950:grid-cols-2 items-center"
      id="different"
    >
      <div className="flex justify-center gap-8">
        <span>
          <DifferentSubComponent
            years={5}
            text="Years of Experience"
            sign="+"
          />
          <DifferentSubComponent years={45} text="Project Served" sign="+" />
        </span>
        <div className="pt-20">
          <DifferentSubComponent years={12} text="Team Member" sign="+" />
          <DifferentSubComponent
            years={96}
            text="Customer Retention Rate"
            sign="%"
          />
        </div>
      </div>

      <div>
        <div className="gap-3 font-merry-bold tracking-tighter flex text-2xl 400:text-3xl 500:text-4xl pb-10">
          <p>
            How We
            <span className="text-txtclr font-bold px-2">
              Different From Other{" "}
              <span className="whitespace-nowrap"> Agency ? </span>
            </span>
          </p>
        </div>
        <div className="grid gap-9 ">
          {differentFromOther.map(({ heading, paragraph }, id) => (
            <div key={id} className="flex  gap-3 items-start">
              <img src="icons/tagCross.svg" alt="cross" className="mt-1" />
              <div>
                <h3 className="text-lg 400:text-xl font-poppins-medium">
                  {heading}
                </h3>
                <p className="text-bodyText font-poppins-regular text-md 400:text-lg">
                  {" "}
                  {paragraph}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowWeDifferent;

const DifferentSubComponent = ({
  years,
  text,
  sign,
}: DifferentSubComponentProps) => {
  return (
    <div className="text-center my-5 hover:bg-txtclr hover:text-white transition-all duration-300 bg-white drop-shadow-2xl 515:w-[210px] 515:h-[210px] 350:w-[150px] 350:h-[150px] 300:w-[130px] 300:h-[130px] w-[100px] h-[100px] grid items-center rounded-[30px]">
      <span>
        <p className="515:text-6xl font-poppins-bold tracking-tight 300:text-4xl text-2xl font-bold">
          {years}
          {sign}
        </p>
        <p className="515:text-lg font-poppins-medium tracking-tight 300:text-base text-xs p-2">
          {text}
        </p>
      </span>
    </div>
  );
};
