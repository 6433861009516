import { processProps } from "../utils/interfaces";

const ProcessCard = ({ src, txt, alt }: processProps) => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col 800:flex-row items-center max-w-[293px] hover:border-txtclr hover:border-2 gap-1 400:gap-2 border-2 rounded-xl 1100:px-p30 1100:py-p30 600:px-5 600:py-p30 py-1.5 px-2 400:px-3">
        <img
          src={src}
          alt={alt}
          className="1100:w-10 1100:h-10 w-4 400:w-5 h-5 500:w-7 500:h-7"
        />
        <p className="text-[10px] 400:text-xs 500:text-base text-center 1100:text-xl font-poppins-semi-bold">
          {txt}
        </p>
      </div>
    </div>
  );
};

export default ProcessCard;
