// import { Tabs } from "@mantine/core";
// import { useState } from "react";
// import WebDevelopment from "./subComponents/WebDevelopment";
// import MobileAppDevelopment from "./subComponents/MobileAppDevelopment";
// import BackendDevelopment from "./subComponents/BackendDevelopment";
// import UiUxDesign from "./subComponents/UiUxDesign";
import ServicesCard from "../../components/ServicesCard";

const Services = () => {
  // const [selectedTab, setSelectedTab] = useState("frontend");
  // const data = [
  //   {
  //     value: "frontend",
  //     text: "Web Development",
  //   },
  //   {
  //     value: "mobile",
  //     text: "Mobile Application Development",
  //   },
  //   {
  //     value: "backend",
  //     text: "Game Development",
  //   },
  //   {
  //     value: "uiux",
  //     text: "UI/UX Design",
  //   },
  // ];

  // function handleMouseEvent(value: string) {
  //   setSelectedTab(value);
  // }

  const techData = [
    {
      id: 1,
      href: "web-development",
      title: "Web Development",
      content:
        "As a leading Web development company, we design website in languages such as React.js, Node.js, JavaScript, Next.js and WordPress to shape Website ideas into reality. We’ve launched some of the most successful website with responsive technology to hit the Web.",
      iconLink: "/Technology_Images/webDevelopmentSVG.svg",
    },
    {
      id: 2,
      href: "mobile-app-development",
      title: "Mobile App Development",
      content:
        "With more than a decade of expertise, Nexasoft offers you a peerless edge when it comes to Android apps development. We understand galore the ins and outs of app development intricacies and create android apps that are resilient, potent, and adaptable. Our expert Android app developers created numerous successful apps that efficiently work on the latest versions of Android technologies.",
      iconLink: "/Technology_Images/androidSVG.svg",
    },
    {
      id: 3,
      href: "game-development",
      title: "Game Development",
      content:
        "Nexasoft is an acclaimed game development station with highly talented Unity game developers. Our Mobile Game Developers are very competent in offering mobile game technology for Android and iOS platforms, with over a decade of rich experience. Nexasoft is a trailblazer in every level of game development and is ranked among the best mobile gaming businesses abroad.",
      iconLink: "/Technology_Images/GameIcon.svg",
    },
    {
      id: 4,
      href: "ui-ux-design",
      title: "UI/UX Design",
      content:
        "UI/UX is the heart of any application. Our developers are well-versed in this trend, and as a result, we create interactive and collaborative prototypes for each application. We accomplish a more personalized, result-oriented, user-centric interface that provides the most interactive user experience feasible.",
      iconLink: "/Technology_Images/UiUxIcon.svg",
    },
  ];

  return (
    <div className="bg-serviceBg px-p83 bg-cover pb-20" id="services">
      <h1 className="text-[1.8rem] 500:text-4xl font-merry-bold tracking-tighter text-center pt-10 500:pt-20 pb-8 500:pb-16">
        We’re Providing <br />
        <span className="font-bold text-txtclr "> High Quality Services </span>
      </h1>

      {/*   <div>
        <Tabs defaultValue="frontend" value={selectedTab}>
          <Tabs.List justify="space-between">
            {data.map(({ value, text }, index) => (
              <Tabs.Tab
                key={index}
                onMouseEnter={() => handleMouseEvent(value)}
                value={value}
                className="font-medium !text-lg !text-wrap 500:!text-xl px-0 500:!px-5 hover:bg-transparent"
              >
                {text}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel value="frontend">
            <WebDevelopment />
          </Tabs.Panel>

          <Tabs.Panel value="mobile">
            <MobileAppDevelopment />
          </Tabs.Panel>

          <Tabs.Panel value="backend">
            <BackendDevelopment />
          </Tabs.Panel>

          <Tabs.Panel value="uiux">
            <UiUxDesign />
          </Tabs.Panel>
        </Tabs>
      </div> */}

      <div className="grid 550:grid-cols-2 900:grid-cols-3 1600:grid-cols-4 gap-10">
        {techData.map(({ id, title, href, iconLink, content }) => (
          <ServicesCard
            key={id}
            title={title}
            content={content}
            iconLink={iconLink}
            href={href}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
