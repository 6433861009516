import Blogs from "../blogs/Blogs";
import ContactUs from "../contact-us/ContactUs";
import Feedback from "../feedbacks/Feedback";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HeroSection from "../hero-section/HeroSection";
import HowWeDifferent from "../how-we-different/HowWeDifferent";
import OurStory from "../story/OurStory";
import OurTechnologies from "../technologies/OurTechnologies";
import Services from "../services/Services";
import OurProcess from "../process/OurProcess";

const Home = () => {
  return (
    <div className="bg-lightBg bg-cover ">
      <Header />
      <HeroSection />
      <HowWeDifferent />
      <OurTechnologies />
      <OurProcess />
      <Services />
      <Feedback />
      <OurStory />
      <Blogs />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
