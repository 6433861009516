import { useLocation, useParams } from "react-router";
import { servicesData } from "../utils/constants";
import { ServicesComponentProps } from "../utils/interfaces";
import { useEffect } from "react";

const ServicesPage = () => {
  const params = useLocation();
  const moveToId = params.hash ? params.hash.substring(1) : "";

  useEffect(() => {

    if (moveToId && moveToId !== "ui-ux-design" && moveToId !== "game-development") {
      const element = document.getElementById(moveToId);
      if (element) {
        const offset = 0; // Adjust the offset value as per your needs (negative value for extra top space)
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }else if(moveToId === "game-development"){
      setTimeout(() => {
        const scrollHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight
        );

        window.scrollTo({
          top: scrollHeight,
          behavior: "smooth",
        });
      }, 100);
      }else {
      window.scrollTo(0, 0);
    }
  }, [moveToId,document.body.scrollHeight]);


  return (
    <>
      {servicesData.map((data, index) => (
        <ServicesItem
          key={data.id}
          id={data.id}
          imageLocation={index % 2 === 0 ? "left" : "right"}
          imagePath={data.imagePath}
          data={data.data}
          mainTitle={data.mainTitle}
          subTitle={data.subTitle}
          description={data.description}
        />
      ))}
    </>
  );
};

export default ServicesPage;

const ServicesItem = ({
  id,
  imagePath,
  data,
  mainTitle,
  subTitle,
  description,
  imageLocation,
}: ServicesComponentProps) => {
  return (
    <div
      id={id}
      className={`grid 800:grid-cols-2 gap-5 px-p83 ${
        imageLocation === "right" ? "bg-white" : "bg-lightBgClr"
      }  py-10 items-center`}
    >
      {imageLocation === "left" && (
        <div  className="flex justify-start">
          <img src={imagePath} alt={id} className="max-[800px]:mx-auto rounded-md" />
        </div>
      )}

      <div className="max-[800px]:order-1">
        <h3 className="text-3xl font-bold">
          <span className="text-btnBgDark"> {mainTitle}</span> {subTitle}
        </h3>
        <p className="py-5">{description}</p>
        {data.map((data: any, index: number) => (
          <div key={index}>
            <span className="flex items-center">
              <img
                src="/icons/DOT.svg"
                width={20}
                alt="point"
                className="mr-3"
              />
              <h4 className="font-bold text-xl">{data.heading}</h4>
            </span>
            <p className="text-gray-500 py-2 pl-8">{data.content}</p>
          </div>
        ))}
      </div>
      {imageLocation === "right" && (
        <div className="flex justify-end">
          <img src={imagePath} alt="Android" className="max-[800px]:mx-auto rounded-md" />
        </div>
      )}
    </div>
  );
};
