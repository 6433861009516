import { footerLinkData, socialLinkData } from "../utils/constants";

const Footer = () => {
  return (
    <div className="bg-white px-p83">
      <div className="grid gap-y-5 375:flex items-center justify-between pt-10">
        <h1 className="font-merry-bold tracking-tighter text-[26px]  500:text-3xl  600:text-4xl">
          Let’s Work <span className="font-bold text-txtclr ">Together</span>
        </h1>
        <div className="flex  items-center">
          {socialLinkData.map(({ id, icon, text, href }) => (
            <a href={href} key={id} target="_blank" rel="noreferrer">
              <img
                src={icon}
                alt={text}
                className="p-1 500:p-2 800:p-4 border border-[#5960801a]"
              />
            </a>
          ))}
        </div>
      </div>

      <p className="my-8 border" />

      <div className="gap-5 flex-col 750:flex-row  750:flex justify-between  ">
        <div className="750:max-w-52 w-full ">
          <span className="flex items-center gap-2">
            <img src="/logo.png" alt="LOGO" className={`w-10 500:w-12`} />
            <h1 className="text-4xl font-poppins-semi-bold text-[#000]">
              NEXASOFT
            </h1>
          </span>
          <p className="text-bodyText text-base pt-4 font-poppins-regular">
            Unlocking Digital Brilliance | Nexasoft - Your Partner in Creative
            Solutions
          </p>
        </div>
        <div className="grid grid-cols-2 gap-y-10 500:flex w-full 750:w-[80%] justify-around 750:pt-0 pt-10">
          <div>
            <h3 className="font-poppins-semi-bold pb-6">Quick Links</h3>
            <div className="grid gap-4">
              {footerLinkData.quickLinks.map(({ id, linkName, href }) => (
                <a
                  href={href}
                  key={id}
                  className="text-bodyText font-poppins-regular hover:text-txtclr"
                >
                  {linkName}
                </a>
              ))}
            </div>
          </div>
          <div>
            <h3 className="font-poppins-semi-bold pb-6">Information</h3>
            <div className="grid gap-4">
              {footerLinkData.information.map(({ id, linkName, href }) => (
                <a
                  href={href}
                  key={id}
                  className="text-bodyText font-poppins-regular hover:text-txtclr"
                >
                  {linkName}
                </a>
              ))}
            </div>
          </div>
          <div>
            <h3 className="font-poppins-semi-bold pb-6">Services</h3>
            <div className="grid gap-4">
              {footerLinkData.services.map(({ id, linkName, href }) => (
                <a
                  href={href}
                  key={id}
                  className="text-bodyText font-poppins-regular hover:text-txtclr"
                >
                  {linkName}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <p className="mt-12 mb-5 border" />

      <div className="grid gap-y-3 515:flex justify-between text-bodyText text-base pb-5">
        <div className="font-poppins-regular">
          Copyright ©2023 by Nexasoft Infotech
        </div>

        {/* <div className="flex gap-2 500:gap-4 pb-5">
          <a href="/">Privacy Policy</a>
          <a href="/">Terms & Conditions</a>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
