import { ServicesCardProps } from "../utils/interfaces";

const ServicesCard = ({
  title,
  content,
  iconLink,
  href,
}: ServicesCardProps) => {
  return (
    <div className="py-3 px-5 bg-[#c3e4ff] text-black rounded-3xl shadow-md shadow-black/30">
      <img src={iconLink} alt={title} width={110} className="py-3" />
      <p className="text-3xl font-poppins-bold tracking-tight min-h-[70px] flex items-center">{title}</p>
      <p className="pt-3 font-poppins-regular line-clamp-6 text-gray-500">
        {content}
      </p>
      <a
        href={`/service#${href}`}
        target="_blank"
        className="py-3 font-semibold flex items-center gap-2 hover:text-btnBgDark transition-all duration-300"
      >
        <span className="font-poppins-regular"> Read More </span>
        <img src="/icons/rightArrow.svg" alt="RIGHT" />
      </a>
    </div>
  );
};

export default ServicesCard;
